<template>
  <div
    class="inprocess-simulation px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="In-Process Simulation Testing" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/inprocesstest-min.jpg"
          width="500"
          height="320"
          alt=""
          title="inprocesstest"
          class="img-responsive wp-image-12609"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            In-Process characterization and classification of explosives is
            necessary to properly identify and manage risks when working with
            explosives, energetics, or pyrotechnic substances and articles.
            Simply using the transport configuration can lead to unplanned for
            and potentially serious consequences. For instance, primers used in
            ammunition are detonable (1.1) when in close contact but are often
            shipped as a 1.4 due to special packaging. Understanding the
            characteristics of the in-process material ensures that proper
            safety requirements are addressed early in the designing and
            planning stages of a production line.
          </p>
          <p>
            This protocol is not intended to replace or modify BATF, DoD or DOT
            classification systems for storage or transportation, but to assist
            the manufacturer of explosives with proper facility design and
            siting of modified or new facilities.
          </p>
          <p>
            SMS has extensive experience completing In-process classification
            testing for industry and govenment for the last 20+ years. SMS works
            with
            <a href="https://etusersgroup.org" target="_blank">
              the ET Users Group
            </a>
            to collaboratively define and standardize in-process tests. A key
            document discussing and outlining the approach and the associated
            tests are outlined in the document
            <router-link title="Library" to="/resources/document-library"
              >“In-Process Classification”.</router-link
            >
            Principles and tests outlined have been included in
            <a href="https://www.nfpa.org/" target="_blank">NFPA 495.</a>
          </p>
          <p>
            For testing consideration, SMS must be provided with information
            needed to understand your material and the objective of the testing,
            as specified in
            <router-link title="Library" to="/resources/document-library"
              >“Standard Required Information for Testing.”</router-link
            >
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Tests Simulating Process Conditions" />
        <Paragraph
          ><h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            In-process Test Series 1
          </h4>
          <ul>
            <li>Impact Sensitivity Test</li>
            <li>Friction Sensitivity Test</li>
            <li>ESD Sensitivity Test</li>
          </ul>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            In-process Test Series 2
          </h4>
          <ul>
            <li>Zero Gap Test</li>
            <li>Internal Ignition</li>
          </ul>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            In-process Test Series 3
          </h4>
          <ul>
            <li>Thermal Stability Test</li>
            <li>Small-Scale Burning Test</li>
            <li>#8 Cap Test</li>
            <li>NOL Card Gap Test</li>
          </ul>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            In-process Test Series 4
          </h4>
          <ul>
            <li>Thermal Stability Test</li>
            <li>Small-Scale Burning Test</li>
            <li>#8 Cap Test</li>
            <li>NOL Card Gap Test</li>
          </ul>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            In-process Test Series 5
          </h4>
          <ul>
            <li>Thermal Stability Test</li>
            <li>Drop Test</li>
          </ul>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            In-process Test Series 6
          </h4>
          <ul>
            <li>Worst-Case Propagation Tests</li>
            <li>External Fire Test</li>
          </ul>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Protective-Enclosure Testing
          </h4>
          <p>
            Each test is described in more detail on our
            <router-link
              title="In-Process Tests"
              to="/testing/in-process-simulation-testing/in-process-tests"
              >In-Process Tests</router-link
            >
            page.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Definitions and Terms" />
        <Paragraph
          ><p>
            <em>In-process</em>, refers to stages of manufacture of an original
            product or an application of that product into another product. The
            material is considered in-process, if it is in an operation,
            process, or manufacturing stage where it is being used, changed,
            transported, mixed or otherwise modified or incorporated into the
            process or product.
          </p>
          <p>
            Two main divisions exist when discussing explosives: substance and
            articles. <em>Substances</em> refer to the actual explosive
            (powders, grains, pellets, etc.). <em>Articles</em> refer to items,
            which contain explosive substances (detonators, igniters, inflators,
            etc.). Both substances and articles can mass react depending on the
            explosive materials they contain and the design configuration used.
            Different tests are needed to evaluate the propagation potential of
            substances and articles.
          </p>
          <p>
            For classification purposes, one looks at both the sensitivity and
            reactivity of the substance or article. <em>Sensitivity</em> refers
            to how easily a material or article is initiated due to various
            stimuli. <em>Reactivity</em> refers to what type or size of event is
            produced once initiation occurs. When the sensitivity and reactivity
            of a substance or article is properly characterized, the data can
            also be used to improve the safety and application of the material
            in addition to in-process classifications as presented here.
          </p>
          <p>
            Pictures, video, key parameters, round-robin results, and
            documentation are detailed at the
            <a href="https://etusersgroup.org" target="_blank"
              >ET Users Group website</a
            >. Please visit and check-out the extensive work that is being
            completed in collaboration with industry and government.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "In Process Simulation Testing",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Because the UN Series/DOT testing only assesses articles and substances in transportation configurations, the manufacturer or processor may have an inaccurate assessment of the article or substance at different stages in the process. Additional testing should be performed to assess potential hazards of the material at different stages in the process."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.inprocess-simulation {
  a {
    color: #aa222c;
  }
  h4 {
    color: black;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
  }
}
</style>
